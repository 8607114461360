<template>
  <div class="container-fluid mt-4 mb-2" ref="content">
    <add-current-weight
      :toggle-modal="toggleAddCurrentWeightModal"
      :patient-id="patient_id"
      @hide-add-current-weight-modal="hideAddCurrentWeightModal"
      @update-weight-logs="getWeightLogs"
    />
    <div>
      <back-button/>
    </div>
    <div class="weight-log-header-wrapper ml-1 mr-1 mb-3">
      <div class="weight-log-header-heading">
        <h1>Weight Logs</h1>
      </div>
      <div class="weight-log-header-buttons">
        <base-button class="btn-secondary-action" @click="downloadLogs">Download</base-button>
        <base-button class="btn-secondary-action" @click="showAddCurrentWeightModal">Add Weight</base-button>
      </div>
    </div>

    <div v-loading="loading">
      <log-card :data="weightLogs" :data-request="weightLogRequest"></log-card>
    </div>

    <div class="pt-2" v-loading="loading">
      <weight-logs-table :data="weightLogs" :loading="loading"></weight-logs-table>
    </div>

  </div>
</template>

<script>
import WeightLogTable from "@/views/Components/Logs/WeightLogs/WeightLogTable";
import LogCard from "@/components/LogCard";
import moment from "moment";
import BackButton from "@/components/Router/BackButton";
import AddCurrentWeight from "@/views/Pages/Logs/WeightLog/AddCurrentWeight";

export default {
  components: {
    weightLogsTable: WeightLogTable,
    BackButton,
    LogCard,
    AddCurrentWeight
  },
  data() {
    return {
      loading: false,
      patient_id: this.$route.params.id,
      weightLogRequest: {
        duration: 'Complete'
      },
      weightLogs: {
        patient: {
          first_name: '',
          last_name: '',
        },
        logs: {},
      },
      toggleAddCurrentWeightModal: false
    }
  },
  methods: {
    /**
     * Show Add Current Weight Modal
     */
    showAddCurrentWeightModal() {
      this.$store.commit('showAddCurrentWeightModal')
    },

    /**
     * Hide Add Current Weight Modal
     */
    hideAddCurrentWeightModal() {
      this.$store.commit('hideAddCurrentWeightModal')
    },

    downloadLogs() {
      this.weightLogsExcel();
    },
    getWeightLogs() {
      let vm = this;
      vm.loading = true;
      vm.weightLogs.logs = {};
      axios.get(`${this.$store.getters.getBaseUrl}/api/weight-logs?patient_id=${this.patient_id}`).then(response => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        vm.weightLogs = response.data.data;
      }).catch(error => {
      }).finally(() => {
        vm.loading = false;
      });
    },
    weightLogsExcel() {
      let vm = this;
      vm.loading = true;
      const config = {
        responseType: "blob"
      };
      axios.post(`${this.$store.getters.getBaseUrl}/api/weight-logs/excel`,
        {
          patient_id: this.patient_id,
        },
        config
      ).then(response => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        const url = URL.createObjectURL(new Blob([response.data], {
          type: 'application/vnd.ms-excel'
        }))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${vm.weightLogs.patient.first_name} ${vm.weightLogs.patient.last_name}'s ${moment()} weight-logs.xlsx`)
        document.body.appendChild(link)
        link.click()
      }).catch(error => {
      }).finally(() => {
        vm.loading = false;
      });
    }
  },
  mounted() {
    this.getWeightLogs();
  }
}
</script>

<style>
.weight-log-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-primary-action a {
  color: white;
}

</style>
