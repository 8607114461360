<template>
  <modal v-loading="loader" :show.sync="$store.state.modals.addCurrentWeightModal">
    <h1 slot="header" class="modal-title pt-2 pl-1" id="modal-title-default">Add Weight</h1>
    <validation-observer ref="addCurrentWeightForm" v-slot="{handleSubmit}">
      <form class="needs-validation" @submit.prevent="handleSubmit(submitForm)">
        <base-input
          type="number"
          step="any"
          :rules="{required: true}"
          v-model="weight"
          name="weight"
          :pattern="pattern"
          label="Weight*"
          placeholder="0">
        </base-input>
        <div class="w-100">
          <span class="date-text">Date*</span>
          <custom-date-picker class="heading-datepicker"
                              :input-data.sync="date"
                              :max-date="new Date()"
                              :show-image-icon="false"
          />
        </div>
        <div class="float-right mt-4">
          <base-button type="secondary" class="ml-auto btn-gray" @click="hideModal">
            Cancel
          </base-button>
          <base-button native-type="submit" type="submit" class="btn-primary-action">
            Save
          </base-button>
        </div>
      </form>
    </validation-observer>
  </modal>
</template>

<script>
import CustomDatePicker from "@/views/Components/CustomDatePicker";
import generateErrorMessageMixin from "@/mixins/generateErrorMessageMixin";

export default {
  name: "AddCurrentWeight",
  props: ['toggleModal', 'patientId'],
  components: {
    CustomDatePicker
  },
  data() {
    return {
      loader: false,
      weight: '',
      date: '',
      pattern: '^[0-9]{1,7}(?:\\.[0-9]{1,3})?$'
    }
  },
  mixins: [
    generateErrorMessageMixin
  ],
  methods: {
    /**
     * Hide the AddCurrentWeight Modal
     */
    hideModal() {
      this.resetForm()
      this.$emit('hide-add-current-weight-modal')
    },

    /**
     * Submit the form
     */
    submitForm() {
      let vm = this
      vm.loader = true
      const payload = {
        patientId: this.patientId,
        weight: this.weight,
        date: this.date
      }
      vm.$store.dispatch('_addPatientCurrentWeight', payload)
        .then(() => {
          this.$emit('hide-add-current-weight-modal')
          this.resetForm()
          this.$emit('update-weight-logs')
          vm.$notify.success({
            title: 'Success',
            message: 'Weight Added Successfully.'
          })
        })
        .catch(error => {
          let message = error.response ? vm.generateErrorMessage(error.response.data.errors) : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'Weight',
            dangerouslyUseHTMLString: true,
            message: message
          })
        })
        .finally(() => {
          vm.loader = false
        })
    },

    /**
     * Reset the form fields
     */
    resetForm() {
      this.weight = ''
      this.date = ''
      this.$refs.addCurrentWeightForm.reset()
    }
  }
}
</script>

<style scoped lang="scss">

.date-text {
  color: #525f7f;
  font-size: 0.875rem;
  font-weight: 600;
}

.heading-datepicker {
  ::v-deep .date-container {
    border: 0;
  }

  ::v-deep .calendar-button {
    margin-left: 6px;
    border: 1px solid transparent;
  }

  ::v-deep .date-input {
    .el-input__inner {
      margin-top: 8px;
      border: 1px solid #dee2e6;
      background-color: white;
      color: #32325d;
      font-weight: 500;

      &::placeholder {
        color: #32325d;;
      }
    }
  }
}

</style>
