<template>
    <div>
        <div v-if="Object.keys(data.logs).length > 0">
            <div class="table-card card" v-for="(logs, date) in data.logs" :key="date">
                <div class="table-card-header card-header border-0">
                    <div class="row">
                        <div class="col-6">
                            <h3 class="mb-0">{{ date }}</h3>
                      </div>
                    </div>
                </div>
                <table class="table table-borderless">
                    <tbody>
                    <tr class="table-row" v-for="(weight, innerKey) in logs" :key="weight.id">
                        <th scope="col">{{ getFormattedDate(weight.date) }}</th>
                        <th scope="col" class="weight-th" :class="getWeightClass(data.logs[date], date, innerKey)">
                            {{ getPrecisedWeight(weight.weight) }}
                        </th>
                    </tr>
                    </tbody>
                </table>

            </div>
        </div>
        <div v-else>
            <card class="no-data">
              <div v-if="loading===true">
                <h3 class="text-center">Fetching..</h3>
              </div>
              <div v-else>
                <h3 class="text-center">No data for this User</h3>
              </div>
            </card>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import {Table, TableColumn, Tooltip} from 'element-ui'

export default {
    name: 'inline-actions-table',
    props: ['data', 'source', 'loading'],
    components: {
        [Tooltip.name]: Tooltip,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
    },
    data() {
        return {
            currentPage: 1,
            selectedRows: [],
            firstComparison: true,
            weightLogs: '',
            lastVal: '',
        };
    },
    computed: {
        getMonths() {
            return Object.keys(this.data.logs);
      }
    },
    methods: {
        getPrecisedWeight(weight) {
          return parseFloat(weight) == parseInt(weight) ? weight : parseFloat(weight).toFixed(1)
        },
        getFormattedDate(date) {
            return moment(date).format('MMMM DD, YYYY')
        },
        getTableDate(date) {
            return moment(date).format('MMMM YYYY');
        },
        getWeightClass(log, date, index) {
            const currentIndex = this.getMonths.findIndex(el => el == date);
            const oneMonthBefore = this.getMonths[currentIndex + 1];
            let compareWeight = null;

            if (log[index + 1] === undefined) {
                if (oneMonthBefore) {
                    compareWeight = parseFloat(this.data.logs[oneMonthBefore][0].weight)
                } else {
                    return 'bg-grey-table';
                }
            } else {
                compareWeight = parseFloat(log[index + 1].weight);
            }

            if (compareWeight) {
                if (parseFloat(log[index].weight) > parseFloat(compareWeight)) {
                    return 'bg-red-table'
                } else if (parseFloat(log[index].weight) < parseFloat(compareWeight)) {
                    return 'bg-green-table';
                } else {
                    return 'bg-grey-table';
                }
            }

        }
    }
}
</script>

<style>
.no-data {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    flex-direction: row;
}

.table-card {
    border-radius: 10px;
    border: 1px solid #f6f6f6 !important;
}

.table-card-header {
    background-color: #F6F9FC !important;
    border: 1px solid #f5f5f5 !important;
}

.table-row:not(:last-child) {
    border-bottom: 1px solid #eaeaea;
}

.weight-th {
    width: 150px;
    text-align: center;
}

.bg-grey-table {
    background-color: lightgrey !important;
}

.bg-green-table {
    background-color: #CEE5D0 !important;
}

.bg-red-table {
    background-color: #DEC7CF !important;
}
</style>
